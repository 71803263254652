.file {
  position: relative;

  &.active {

    .file {

      &__icon {

        &--add {
          display: none; }

        &--added {
          display: block; } }

      &__caption {

        &--add {
          display: none; }

        &--added {
          display: block; } } } }

  &.error {

    .file {

      &__label {
        border-color: #CC3559; } } }

  &__label {
    position: relative;
    display: flex;
    align-items: center;
    padding: 16px 24px;
    background: #15181B;
    border: 2px dashed #626262;
    border-radius: 10px;
    cursor: pointer;
    transition: border 300ms ease;

    @include screen-md {
      padding: 16px 24px; }

    @include screen-sm {
      flex-wrap: wrap;
      padding: 16px 24px 20px 24px; }

    body:not(.mobile) &:hover {

      .btn {

        &__name {
          color: #0C100F; }

        &__bg {
          opacity: 1;
          transform: translate(-50%, -50%) scale(1.6); } } } }

  &__input {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 0; }

  &__icon {
    flex-shrink: 0;
    width: 24px;
    height: 24px;
    margin-right: 16px;
    display: none;

    @include screen-md {
      margin-right: 12px; }

    &--add {
      display: block; }

    &--added {
      display: none; }

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: contain; } }

  &__caption {
    margin-right: 32px;
    display: none;

    @include screen-md {
      margin-right: 16px; }

    @include screen-sm {
      margin: 0;
      width: calc(100% - 36px); }

    &--add {
      display: block; }

    &--added {
      display: none; } }

  &__text {
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;
    letter-spacing: -0.02em;
    color: #CFCFCF;
    opacity: 0.4;

    @include screen-md {
      font-size: 13px; }

    &--white {
      opacity: 1;
      word-break: break-all; } }

  &__button {
    width: 138px;
    flex-shrink: 0;
    margin-left: auto;

    @include screen-md {
      width: 120px; }

    @include screen-sm {
      width: 100%;
      margin-top: 16px; } } }
