.banner {
  background: #2D68FF;
  position: relative;
  overflow: hidden;

  &__wrapper {
    padding: em(107) 0;

    @include screen-md {
      padding: 48px 0; } }

  &__image {
    position: absolute;
    top: 50%;
    left: em(-140);
    width: 54.28%;
    min-height: 150%;
    transform: translateY(-50%);
    border-radius: 50%;
    overflow: hidden;

    @include screen-md {
      width: 64%;
      left: -107px;
      min-height: 120%;
      max-width: 550px; }

    @include screen-sm {
      width: 100%;
      position: relative;
      left: auto;
      top: auto;
      transform: none;
      max-width: none;
      width: auto;
      margin-left: -77px;
      margin-right: -77px;
      margin-top: -63%;
      margin-bottom: 24px; }

    &::before {
      content: '';
      display: block;
      padding-top: 100%; }

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: block;
      object-fit: cover;

      @include screen-sm {
        object-fit: contain;
        object-position: bottom; } } }

  &__caption {
    margin-left: 58%;
    max-width: em(480);

    @include screen-md {
      margin-left: 56%;
      max-width: none; }

    @include screen-sm {
      margin-left: 0;
      padding: 0 16px; } }

  &__top-text {
    color: #FED74C;
    padding-bottom: em(8);

    span {
      font-weight: 500;
      font-size: em(24);
      line-height: 125%;
      letter-spacing: -0.01em;

      @include screen-md {
        font-size: 16px; } }

    @include screen-md {
      padding-bottom: 6px; } }

  &__title {
    color: #FFFFFF;
    padding-bottom: em(48);

    span {
      font-weight: 600;
      font-size: em(48);
      line-height: 125%;
      letter-spacing: -0.01em;

      @include screen-md {
        font-size: 28px; } }

    @include screen-md {
      padding-bottom: 24px; } }

  &__text {
    color: #FFFFFF;
    padding-bottom: em(34);

    span {
      font-weight: 400;
      font-size: em(20);
      line-height: 125%;
      letter-spacing: -0.01em;

      @include screen-md {
        font-size: 15px; } }

    @include screen-md {
      padding-bottom: 20px; } }

  &__list {
    list-style-type: none;
    padding-bottom: em(48);

    @include screen-md {
      padding-bottom: 32px; } }

  &__item {
    color: #FFFFFF;
    margin-bottom: em(12);
    padding-left: em(32);
    position: relative;

    span {
      font-weight: 500;
      font-size: em(22);
      line-height: 125%;
      letter-spacing: -0.01em;

      @include screen-md {
        font-size: 15px; } }

    @include screen-md {
      margin-bottom: 6px;
      padding-left: 24px; }

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: em(8);
      height: em(8);
      background: #FFD84C;

      @include screen-md {
        width: 8px;
        height: 8px; } }

    &:last-child {
      margin-bottom: 0; } }

  &__btn {
    min-width: em(240);

    @include screen-sm {
      min-width: auto;
      width: 100%; } } }

