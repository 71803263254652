.tippy-box {
  background: #FFFFFF;
  box-shadow: 0px 8px 40px rgba(99, 108, 122, 0.18);
  border-radius: 12px;
  box-sizing: border-box;
  padding: 22px;
  width: 368px;
  max-width: 368px !important;
  font-weight: 400;
  font-size: 13px;
  line-height: 150%;
  color: #495575;

  @include screen-sm {
    width: 320px;
    max-width: 320px !important; } }
