.contacts {
  display: flex;
  position: relative;
  align-items: center;

  @include screen-sm {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start; }

  &__info {
    width: 34.86%;
    margin-right: em(100);
    flex-shrink: 0;

    @include screen-md {
      width: 50%;
      margin-right: 43px; }

    @include screen-sm {
      width: 100%;
      margin-right: 0;
      margin-bottom: 70px; } }

  &__header {
    padding-bottom: em(80);

    @include screen-md {
      padding-bottom: 60px; } }

  &__list {}

  &__item {
    padding-bottom: em(48);
    display: flex;

    @include screen-md {
      padding-bottom: 32px; } }

  &__icon {
    width: em(72);
    height: em(72);
    margin-right: em(32);
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: #FFD84C;
    overflow: hidden;

    @include screen-md {
      width: 52px;
      height: 52px;
      margin-right: 18px; }

    img {
      display: block;
      width: em(40);
      height: em(40);
      object-fit: contain;

      @include screen-md {
        width: 32px;
        height: 32px; } } }

  &__caption {
    width: 100%;
    max-width: em(350);

    @include screen-md {
      max-width: 350px; }

    @include screen-sm {
      max-width: none; } }

  &__title {
    padding-bottom: em(10);
    color: #242424;

    span {
      font-weight: 500;
      font-size: em(24);
      line-height: 125%;
      letter-spacing: -0.01em;

      @include screen-md {
        font-size: 20px; } }

    @include screen-md {
      padding-bottom: 8px; } }

  &__phone {
    font-weight: 500;
    font-size: em(30);
    line-height: 150%;
    color: #242424;

    @include screen-md {
      font-size: 24px; } }

  &__mail {
    font-weight: 400;
    font-size: em(20);
    line-height: 150%;
    color: #2D68FF;

    @include screen-md {
      font-size: 16px; }

    span {
      border-bottom: 1px solid #2D68FF;
      transition: border 300ms ease; }

    body:not(.mobile) &:hover {

      span {
        border-color: transparent; } } }

  &__text {
    font-weight: 500;
    font-size: em(20);
    line-height: 150%;
    color: #242424;

    @include screen-md {
      font-size: 16px; }

    span {
      color: #595D62;
      font-weight: 400; } }

  &__location {
    margin-top: 20px;
    display: flex;

    @include screen-xxl {
      flex-direction: column; }

    @include screen-md {
      margin-top: 8px; }

    &-text {
      padding-right: em(14);

      span {
        font-weight: 400;
        font-size: em(16);
        line-height: 150%;
        color: #595D62;

        @include screen-max(1560) {
          font-size: 13px; }

        @include screen-md {
          font-size: 16px; } }

      @include screen-md {
        padding-right: 0;
        padding-bottom: 12px; } }

    a {
      display: inline-flex;
      align-items: center;

      span {
        border-bottom: 1px solid #2D68FF;
        transition: border 300ms ease;
        font-size: em(16);
        line-height: 150%;
        font-weight: 500;
        letter-spacing: -0.01em;
        color: #2D68FF;

        @include screen-max(1560) {
          font-size: 13px; }

        @include screen-md {
          font-size: 14px; } }

      body:not(.mobile) &:hover {

        span {
          border-color: transparent; }

        svg {
          transform: translateX(5px); } }

      svg {
        display: block;
        width: em(16);
        height: em(16);
        stroke: #2D68FF;
        flex-shrink: 0;
        margin-left: em(16);
        transition: transform 300ms ease;

        @include screen-md {
          width: 16px;
          height: 16px;
          margin-left: 12px; } } } }

  &__image {
    width: 100%;
    border-radius: em(30);
    overflow: hidden;
    position: relative;
    filter: drop-shadow(0px 14px 54px rgba(0, 0, 0, 0.1));

    @include screen-md {
      margin-right: -40px;
      border-radius: 20px 0 0 20px; }

    @include screen-sm {
      width: calc(100% + 32px);
      margin-left: -16px;
      margin-right: -16px;
      border-radius: 0; }

    &::before {
      content: '';
      display: inline-block;
      padding-top: 74.77%;

      @include screen-md {
        padding-top: 150%; }

      @include screen-sm {
        padding-top: 74.77%; } }

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover; } } }

