.footer {
  position: relative;
  background: #26304A;

  &__wrapper {
    padding: em(60) 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;

    @include screen-md {
      padding: 40px 0;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start; }

    @include screen-sm {
      align-items: center;
      text-align: center; } }

  &__copy {
    font-weight: 500;
    font-size: em(16);
    line-height: 125%;
    letter-spacing: -0.01em;
    color: #FFFFFF;

    @include screen-md {
      font-size: 16px;
      padding-bottom: 20px; }

    @include screen-sm {
      padding-bottom: 24px; } }

  &__socials {
    display: flex;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    @include screen-md {
      position: relative;
      top: auto;
      left: auto;
      transform: none; }

    @include screen-sm {
      margin-bottom: 40px; } }

  &__social {
    margin: 0 em(6);
    width: em(40);
    height: em(40);
    border-radius: 50%;
    overflow: hidden;
    flex-shrink: 0;
    background: #3A4666;
    transition: background 300ms ease;

    @include screen-md {
      margin: 0 6px;
      width: 40px;
      height: 40px; }

    body:not(.mobile) &:hover {
      background: #4A577A; }

    &:active {
      background: #1A233A; }

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: contain; } }

  &__develop {

    @include screen-md {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%); }

    @include screen-sm {
      position: relative;
      top: auto;
      transform: none; } } }
