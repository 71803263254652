.text-slider {

  &__item {
    min-height: em(114 + 10);
    height: auto;
    padding-bottom: em(10);

    @include screen-max(1312) {
      padding-bottom: 10px;
      min-height: (75px + 10px); }

    @include screen-md {
      min-height: calc(18px * 3 + 10px);
      padding-bottom: 10px; }

    @include screen-sm {
      min-height: calc(18px * 3 + 20px);
      padding-bottom: 20px; } }

  &__wrapper {
    display: flex;
    align-items: center;

    @include screen-md {
      flex-direction: row-reverse; }

    @include screen-sm {
      flex-direction: row;
      justify-content: center; } }

  &__icon {
    width: em(28);
    height: em(28);
    margin-right: em(12);
    flex-shrink: 0;

    @include screen-max(1312) {
      width: 18px;
      height: 18px;
      margin-right: 8px; }

    @include screen-md {
      width: 28px;
      height: 28px;
      margin-right: 0;
      margin-left: 12px; }

    @include screen-sm {
      margin-right: 12px;
      margin-left: 0; }

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: contain; } }

  &__text {
    font-weight: 500;
    font-size: em(22);
    line-height: 170%;
    color: #242424;

    @include screen-max(1312) {
      font-size: 15px; }

    @include screen-md {
      font-size: 15px;
      line-height: 18px;
      text-align: right; }

    @include screen-sm {
      text-align: center; } }

  .swiper-pagination-bullets, .swiper-pagination-horizontal.swiper-pagination-bullets {
    position: relative;
    left: auto;
    transform: none;
    bottom: auto;
    padding: 0 0 0 0;
    width: auto;
    text-align: left;

    @include screen-md {
      text-align: right;
      padding: 0 0 0 0; }

    @include screen-sm {
      text-align: center;
      padding: 0 0 0 0; }

    .swiper-pagination-bullet {
      width: em(14);
      height: em(14);
      transform: scale(1) !important;
      background: #000;
      opacity: .2 !important;
      border: 2px solid #fff;
      margin: 0 em(18) !important;

      @include screen-md {
        width: 12px;
        height: 12px;
        margin: 0 10px !important; }

      &:first-child {
        margin-left: 6px !important; }

      &:last-child {
        margin-right: 4px !important; }

      &.swiper-pagination-bullet-active {
        background: #fff;
        opacity: 1 !important;
        border-color: #2D68FF;
        transform: scale(1.2) !important; } } } }
