@import "input/input";
@import "file/file";
@import "textarea/textarea";
@import "swiper-navigation/swiper-navigation";
@import "text-slider/text-slider";
@import "services-slider/services-slider";
@import "more-link/more-link";
@import "banner/banner";
@import "steps-slider/steps-slider";
@import "faq/faq";
@import "f-slider/f-slider";
@import "contacts/contacts";
@import "develop/develop";
@import "s-list/s-list";
@import "f-plate/f-plate";
