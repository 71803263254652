@mixin screen-xs {
  @media only screen and (max-width: 479px) {
    @content; } }

@mixin screen-sm {
  @media only screen and (max-width: 767px) {
    @content; } }

@mixin screen-md {
  @media only screen and (max-width: 1024px) {
    @content; } }

@mixin screen-lg {
  @media only screen and (max-width: 1279px) {
    @content; } }

@mixin screen-xl {
  @media only screen and (max-width: 1439px) {
    @content; } }

@mixin screen-xxl {
  @media only screen and (max-width: 1600px) {
    @content; } }

@mixin screen-xxxl {
  @media only screen and (max-width: 1700px) {
    @content; } }

@mixin screen-p-xl {
  @media (max-aspect-ratio: 1/1) and (min-width: 1025px) {
    @content; } }

@mixin screen-custom($min-width, $max-height) {
  @media only screen and (min-width: #{$min-width}px) and (max-height: #{$max-height}px) {
    @content; } }

@mixin screen-max($max-width) {
  @media only screen and (max-width: #{$max-width}px) {
    @content; } }
