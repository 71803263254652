.faq-section {
  background: #fff;
  overflow: hidden;

  &__header {
    padding-bottom: em(60);

    @include screen-md {
      padding-bottom: 60px; }

    @include screen-sm {
      text-align: center;
      padding-bottom: 40px; } }

  &__wrapper {
    position: relative;
    padding: em(120) 0;

    @include screen-md {
      padding: 80px 0; }

    @include screen-sm {
      padding: 0 0 60px 0; } }

  &__list {
    width: 68.13%;
    position: relative;
    z-index: 2;

    @include screen-md {
      width: 74.27%; }

    @include screen-sm {
      width: 100%; } }

  &__image {
    position: absolute;
    width: em(815);
    height: em(1109);
    top: 0;
    right: em(-210);
    z-index: 1;

    @include screen-md {
      width: 505px;
      height: 674px;
      right: -127px; }

    @include screen-sm {
      position: relative;
      right: auto;
      width: 100%;
      height: auto;
      overflow: hidden;
      margin-bottom: 18px; }

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: contain;

      @include screen-sm {
        width: 127%;
        position: relative;
        left: -96px;
        margin-top: -90px; } } } }
