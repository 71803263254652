.faq {
  position: relative;

  &__item {
    background: #F2F6FC;
    border-radius: em(16);
    margin-bottom: em(16);
    padding: em(30) em(80);
    display: block;
    border: 2px solid #F2F6FC;
    transition: background 300ms ease, border 300ms ease;

    @include screen-md {
      padding: 16px 50px;
      margin-bottom: 10px;
      border-radius: 12px; }

    &:last-child {
      margin-bottom: 0; }

    &.active {
      background: #000;
      background: #FFFFFF;
      border-color: #C1D4FF;
      box-shadow: 0px 7px 20px rgba(65, 100, 221, 0.16);

      .faq {

        &__content {
          display: block; }

        &__header {

          &::after {
            transform: translateY(-50%) rotate(-180deg); } } } } }

  &__header {
    position: relative;
    color: #242424;

    span {
      font-weight: 500;
      font-size: em(22);
      line-height: 125%;
      letter-spacing: -0.01em;

      @include screen-max(1310) {
        font-size: 15px; }

      @include screen-md {
        font-size: 15px; } }

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: em(-50);
      transform: translateY(-50%);
      width: em(30);
      height: em(30);
      background: url('../img/faq/icon.svg') 50% 50% no-repeat;
      background-size: contain;

      @include screen-md {
        width: 24px;
        height: 24px;
        left: -38px; } }

    &::after {
      content: '';
      position: absolute;
      top: 50%;
      right: em(-50);
      transform: translateY(-50%);
      width: em(30);
      height: em(30);
      background: url('../img/faq/arrow.svg') 50% 50% no-repeat;
      background-size: contain;
      transition: transform 300ms ease;

      @include screen-md {
        width: 24px;
        height: 24px;
        right: -38px; } } }

  &__content {
    display: none;
    color: #595D62;
    padding-top: em(28);

    span {
      font-weight: 400;
      font-size: em(18);
      line-height: 150%;

      @include screen-max(1494) {
        font-size: 14px; }

      @include screen-md {
        font-size: 14px;
        line-height: 140%; } }

    @include screen-md {
      padding-top: 16px;
      margin-left: -26px; } } }

