.s-list {
  position: relative;
  padding: 0 em(40);

  @include screen-md {
    padding: 0; }

  &__item {
    display: flex;
    padding-bottom: em(74);
    border-bottom: 1px solid #B6BBCB;
    margin-bottom: em(50);
    position: relative;

    @include screen-md {
      flex-direction: column;
      padding-left: 160px;
      padding-bottom: 50px; }

    @include screen-sm {
      align-items: center;
      justify-content: center;
      padding-left: 0; } }

  &__visual {
    width: 20.62%;
    flex-shrink: 0;

    @include screen-md {
      width: 100%; }

    @include screen-sm {
      display: flex;
      flex-direction: column-reverse;
      align-items: center; } }

  &__title {
    color: #242424;
    padding-bottom: em(40);

    span {
      font-weight: 500;
      font-size: em(40);
      line-height: 125%;
      letter-spacing: -0.01em;

      @include screen-md {
        font-size: 24px; } }

    @include screen-md {
      padding-bottom: 32px;
      font-size: 24px; } }

  &__image {
    width: em(120);
    height: em(120);
    flex-shrink: 0;

    @include screen-md {
      position: absolute;
      top: 0;
      left: 0;
      width: 120px;
      height: 120px; }

    @include screen-sm {
      position: relative;
      margin-bottom: 32px; }

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: contain; } }

  &__content {
    width: 100%; }

  &__row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: em(17) em(26);

    @include screen-md {
      padding: 14px 16px; }

    &:nth-child(odd) {
      background: #F0FAFF; } }

  &__value {
    font-weight: 500;
    font-size: em(22);
    line-height: 125%;
    letter-spacing: -0.01em;
    color: #242424;

    padding-right: 16px;

    &:last-child {
      padding-right: 0; }

    @include screen-md {
      font-size: 16px; } } }
