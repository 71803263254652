.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  outline: none;
  padding: 0 em(26);
  background: #2D68FF;
  height: em(72);
  border-radius: em(45);
  cursor: pointer;
  box-shadow: none;
  transition: background 300ms ease, width 300ms ease, border 50ms ease 250ms;
  position: relative;
  overflow: hidden;
  border: none;
  isolation: isolate;

  font-weight: 600;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #FFFFFF;

  @include screen-md {
    height: 48px;
    border-radius: 45px;
    padding: 0 20px; }

  span {
    font-size: em(17);
    line-height: 141%;

    @include screen-md {
      font-size: 14px;
      line-height: 24px; } }

  body:not(.mobile) &:hover {
    background: #1E5DFF; }

  &:active {
    background: #0D48E0; }

  &.is-loading {
    pointer-events: none;
    cursor: auto;
    padding: 0 !important;
    width: em(72);

    @include screen-md {
      width: 48px; }

    span {
      display: none; }

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: em(40);
      height: em(40);
      background: url('../img/btn/spinner.svg') 50% 50% no-repeat;
      background-size: contain;
      z-index: 3;

      @include screen-md {
        width: 24px;
        height: 24px; } } }

  &--md {
    height: em(56);
    padding: 0 em(24);

    @include screen-md {
      height: 48px;
      padding: 0 18px; }

    span {
      font-size: em(15);
      line-height: 160%;
      letter-spacing: 0.02em;

      @include screen-max(1366) {
        font-size: 11px; }

      @include screen-md {
        font-size: 14px;
        line-height: 22px; } } }

  &--fw {
    width: 100%; }

  &--yellow {
    background: #FED74C;

    span {
      color: #2C2C2C; }

    body:not(.mobile) &:hover {
      background: #FFE279; }

    &:active {
      background: #FFD53D; } } }
