.textarea {

  &--sm {

    .textarea {

      &__field {
        padding: 19px 24px;
        border-radius: 10px;
        background: #15181B;
        border-width: 1px;
        font-size: 16px;
        min-height: 105px;

        @include screen-md {
          padding: 12px 16px;
          font-size: 14px;
          line-height: 140%; }

        &::placeholder {
          opacity: .4;
          font-weight: 400;
          font-size: 15px;

          @include screen-md {
            font-size: 14px; } } } } }

  &.error {

    .textarea__field {
      border-color: #CC3559; } }

  &__label {
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;
    letter-spacing: -0.02em;
    color: #CFCFCF;
    padding-bottom: 12px;

    @include screen-md {
      font-size: 15px; } }

  &__field {
    width: 100%;
    display: block;
    border: 2px solid #626262;
    background: none;
    border-radius: 12px;
    outline: none !important;
    box-shadow: nond;

    font-weight: 400;
    font-size: 18px;
    line-height: 120%;
    letter-spacing: -0.02em;
    color: #FFFFFF;

    padding: 23px 30px;

    resize: none;
    min-height: 150px;
    transition: border 300ms ease;

    @include screen-md {
      font-size: 16px;
      height: 56px;
      line-height: 56px;
      padding: 0 20px; }

    &::placeholder {
      color: #fff;
      opacity: 0.4;
      font-weight: 400; }

    &:focus, &:active {
      border-color: #53F0CE; } } }
