.more-link {
  display: flex;
  align-items: center;
  position: relative;
  padding: em(28) em(28) em(28) 0;
  user-select: none;

  @include screen-md {
    padding: 15px 13px 15px 0; }

  body:not(.mobile) &:hover {

    .more-link {

      &__icon {

        &::before {
          background: #FFE279; }

        svg {
          transform: translateX(6px); } } } }

  &:active {

    .more-link {

      &__icon {

        &::before {
          background: #FFD53D; } } } }

  &__text {
    color: #242424;
    margin-right: em(16);
    position: relative;

    span {
      font-weight: 500;
      font-size: em(24);
      line-height: 125%;
      letter-spacing: -0.01em;

      @include screen-md {
        font-size: 16px; } }

    @include screen-md {
      margin-right: 10px; } }

  &__icon {
    width: em(24);
    height: em(24);
    position: relative;

    @include screen-md {
      width: 18px;
      height: 18px; }

    &::before {
      content: '';
      position: absolute;
      width: em(80);
      height: em(80);
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background: #FFD84C;
      border-radius: 50%;
      z-index: -1;
      transition: background 300ms ease;

      @include screen-md {
        width: 48px;
        height: 48px; } }

    svg {
      position: relative;
      display: block;
      width: 100%;
      height: 100%;
      stroke: #292D32;
      transition: transform 300ms ease; } } }
