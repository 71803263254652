.steps-slider {
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: em(200);
    left: 0;
    width: 100%;
    height: 1px;
    background: #B6BBCB;

    @include screen-md {
      top: 100px; } }

  &__container {
    padding: em(100) 0 em(42) 0;
    overflow: visible;

    @include screen-md {
      padding: 0; } }

  &__item {
    height: auto;
    width: em(260) !important;

    @include screen-md {
      width: 247px !important; }

    @include screen-sm {
      width: 247px !important;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center; }

    &.swiper-slide-active {

      .steps-slider {

        &__badge, &__title, &__description {
          opacity: 1; } } } }

  &__header {
    display: inline-block;
    position: relative;
    padding-bottom: em(56);

    @include screen-md {
      padding-bottom: 40px; } }

  &__image {
    width: em(200);
    height: em(200);
    flex-shrink: 0;
    border-radius: 50%;
    overflow: hidden;
    position: relative;
    transition: transform 300ms ease;

    @include screen-md {
      width: 200px;
      height: 200px; }

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: relative;
      z-index: 1; } }

  &__badge {
    width: em(60);
    height: em(60);
    border-radius: 50%;
    overflow: hidden;
    background: #FFD84C;

    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;

    color: #242424;
    transition: opacity 300ms ease;

    span {
      font-weight: 500;
      font-size: em(26);
      line-height: 125%;
      letter-spacing: -0.01em;

      @include screen-md {
        font-size: 20px; } }

    @include screen-sm {
      opacity: 0; }

    @include screen-md {
      width: 56px;
      height: 56px; } }

  &__title {
    color: #242424;
    padding-bottom: em(20);
    width: 100%;
    max-width: em(230);
    transition: opacity 300ms ease;

    span {
      font-weight: 500;
      font-size: em(26);
      line-height: 140%;

      @include screen-md {
        font-size: 26px; } }

    @include screen-md {
      max-width: 230px;
      padding-bottom: 20px; }

    @include screen-sm {
      opacity: 0; } }

  &__description {
    font-weight: 400;
    font-size: em(20);
    line-height: 150%;
    color: #595D62;

    transition: opacity 300ms ease;

    @include screen-sm {
      opacity: 0; }

    @include screen-md {
      font-size: 16px; } }

  .swiper-btn {

    &--prev, &--next {
      top: calc(76px); }

    &--prev {
      left: calc(50% - 175px); }

    &--next {
      right: calc(50% - 175px); } } }
