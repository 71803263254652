.develop {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  transition: all 300ms ease;
  position: relative;

  body:not(.mobile) &:hover {

    .develop {

      &__icon {

        &--planet {
          animation: rotate 600ms ease; }

        &--satellite {
          animation: rotate 600ms ease 600ms; } } } }

  &__icons {
    flex-shrink: 0;
    width: em(32);
    height: em(32);
    position: relative;
    display: flex;
    justify-content: center;
    margin: 0 em(6);
    align-items: center;

    @include screen-md {
      margin: 0 6px;
      width: 32px;
      height: 32px; } }

  &__icon {
    display: block;
    width: em(24);
    height: em(24);
    flex-shrink: 0;
    position: relative;

    @include screen-md {
      width: 24px;
      height: 24px; }

    svg {
      width: 100%;
      height: 100%; }

    &--satellite {
      position: absolute;
      width: 100% !important;
      height: 100% !important;
      top: 0;
      left: 0;

      &::before {
        content: '';
        width: em(3);
        height: em(3);
        border-radius: 50%;
        position: absolute;
        top: em(3);
        left: em(3);
        background: #FFD84C;

        @include screen-md {
          width: 3px;
          height: 3px;
          top: 3px;
          left: 3px; } } } }

  &__text {
    display: block;
    flex-shrink: 0;
    letter-spacing: -0.01em;
    line-height: 126%;

    span {
      font-weight: 300;
      font-size: em(15);
      color: #E8F1FD;

      @include screen-md {
        font-size: 15px; } }

    strong {
      font-weight: 500;
      color: #fff;
      font-size: em(16);

      @include screen-md {
        font-size: 16px; } } } }

@keyframes rotate {
  from {
    transform: rotate(0); }
  to {
    transform: rotate(360deg); } }
