.about {
  padding-top: em(80);
  padding-bottom: em(20);

  @include screen-md {
    padding-bottom: 49px;
    padding-top: 80px; }

  @include screen-sm {
    padding: 80px 16px 100px 16px; }

  &__title {
    padding-bottom: em(16);

    @include screen-md {
      padding-bottom: 60px; }

    @include screen-sm {
      padding-bottom: 24px; } }

  &__row {
    display: flex;
    align-items: center;

    @include screen-md {
      flex-direction: column;
      align-items: flex-start; } }

  &__col {

    &:first-child {
      width: 53%;
      flex-shrink: 0;
      margin-right: em(31);

      @include screen-md {
        width: 100%;
        margin-right: 0;
        display: flex;
        align-items: center;
        padding-bottom: 53px; }

      @include screen-sm {
        flex-direction: column-reverse;
        padding-bottom: 28px; } }

    &:last-child {
      width: 100%;
      max-width: em(616);

      @include screen-md {
        max-width: none; } } }

  &__image {
    width: 100%;
    max-width: em(686);
    position: relative;
    border-radius: 50%;
    overflow: hidden;
    flex-shrink: 0;

    @include screen-md {
      width: 44.34%;
      margin-right: 50px;
      max-width: none; }

    @include screen-sm {
      width: 82%;
      margin-right: 0; }

    &::before {
      content: '';
      display: inline-block;
      padding-top: 100%; }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.05);
      z-index: 2; }

    img {
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      z-index: 1; } }

  &__description {
    color: #595D62;
    padding-bottom: em(56);
    padding-left: em(50);

    span {
      font-weight: 300;
      font-size: em(20);
      line-height: 150%;

      @include screen-max(1801) {
        font-size: 15px; }

      @include screen-md {
        font-size: 14px;
        line-height: 140%; } }

    @include screen-md {
      display: none;
      padding-left: 0;
      padding-bottom: 0;
      padding-right: 40px; }

    @include screen-sm {
      padding-right: 0;
      padding-bottom: 32px; }

    &--sm {
      display: none;

      @include screen-md {
        display: block; } } }

  &__list {
    position: relative;

    @include screen-md {
      display: flex;
      margin-left: -5px;
      margin-right: -5px; }

    @include screen-sm {
      flex-direction: column;
      margin: 0; } }

  &__list-item {
    padding: em(50) 0 em(28) em(50);
    position: relative;

    @include screen-md {
      padding: 24px 5px 0 29px;
      width: calc(100% / 3); }

    @include screen-sm {
      width: 100%;
      padding: 24px 0 16px 2px; }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: em(140);
      height: em(140);
      border-radius: 50%;
      background: #F0FAFF;
      z-index: 0;

      @include screen-md {
        left: 5px;
        width: 100px;
        height: 100px; }

      @include screen-sm {
        left: -24px; } } }

  &__list-title {
    color: #242424;
    padding-bottom: em(16);
    position: relative;
    z-index: 1;

    span {
      font-weight: 500;
      font-size: em(28);
      line-height: 125%;
      letter-spacing: -0.01em;

      @include screen-max(1305) {
        font-size: 19px; }

      @include screen-md {
        font-size: 20px; } }

    @include screen-md {
      padding-bottom: 16px;
      width: 100%;
      max-width: 180px; }

    @include screen-sm {
      max-width: none; } }

  &__list-text {
    color: #595D62;
    position: relative;
    z-index: 1;

    span {
      font-weight: 400;
      font-size: em(18);
      line-height: 150%;

      @include screen-max(1495) {
        font-size: 14px; }

      @include screen-md {
        font-size: 14px;
        line-height: 140%; } } } }
