.selectize {

  &-input {
    -moz-appearance: none;
    -webkit-appearance: none;
    -ms-progress-appearance: none;
    box-shadow: none;
    outline: none;
    display: flex !important;
    align-items: center;
    width: 100%;
    border: 1px solid #DADFE3;
    height: 48px;
    line-height: 48px;
    font-size: 14px;
    letter-spacing: 0.01em;
    color: #404750;
    padding: 0 17px;
    font-family: sans-serif;
    font-weight: 400;
    transition: border 300ms ease;
    border-radius: 0;
    box-sizing: border-box !important;
    margin: 0 !important;

    @include screen-md {
      height: 40px;
      line-height: 40px;
      font-size: 12px; }

    &>input {
      margin: 0 !important;
      font-size: 14px;
      box-sizing: border-box !important; }

    &.dropdown-active {
      border-radius: 0; }

    &.focus {
      box-shadow: none; } }

  &-control.single .selectize-input:after {
    width: 18px;
    height: 18px;
    background: url('../img/dropdown/arrow-18.svg') 50% 50% no-repeat;
    background-size: contain;
    border: none;
    margin-top: 0;
    top: 50%;
    transform: translateY(-50%);
    transition: transform 300ms ease; }

  &-control.single .selectize-input.dropdown-active:after {
    margin: 0;
    border: none;
    transform: translateY(-50%) rotate(-180deg); }

  &-dropdown {

    .option {
      font-family: sans-serif;
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;
      letter-spacing: 0.01em;
      color: #404750;
      padding: 12px 17px;
      cursor: pointer;
      transition: background 300ms ease;

      @include screen-md {
        font-size: 12px;
        line-height: 16px; }

      body:not(.mobile) &:hover, &.active {
        background: #F5F6F7; } } } }
