.m-screen {
  padding: em(281) 0 em(285) 0;
  position: relative;
  overflow: hidden;

  @include screen-md {
    padding: 166px 0 80px 0;
    border-bottom: 1px solid #D9D9D9;
    margin-bottom: 0; }

  @include screen-sm {
    padding: 104px 0 0 0;
    border-bottom: none;
    margin-bottom: 0;
    overflow: hidden; }

  &__wrapper {
    width: 100%;

    @include screen-md {
      padding: 0; } }

  &__caption {
    width: 100%;
    max-width: em(605);

    @include screen-md {
      display: flex;
      flex-direction: column;
      max-width: none;
      padding: 0 20px;
      position: relative; }

    @include screen-sm {
      padding: 0 16px; } }

  &__header {
    position: relative;
    z-index: 2;
    padding-bottom: em(72);

    @include screen-md {
      order: 2;
      padding-bottom: 40px;
      width: 100%;
      max-width: 379px; }

    @include screen-sm {
      order: 1;
      padding-bottom: 55px; } }

  &__title {
    padding-bottom: em(32);

    @include screen-md {
      padding-bottom: 20px; }

    h1 {
      color: #242424;
      font-weight: 500;
      font-size: em(76);
      line-height: 125%;
      letter-spacing: -0.01em;

      @include screen-md {
        font-size: 44px;
        line-height: 125%; }

      @include screen-sm {
        font-size: 36px; } } }

  &__description {
    font-weight: 400;
    font-size: em(19);
    line-height: 170%;
    color: #242424;

    @include screen-max(1534) {
      font-size: 15px; }

    @include screen-md {
      font-size: 14px;
      line-height: 160%; } }

  &__button {
    padding-bottom: em(50);
    width: em(300);
    position: relative;
    z-index: 2;

    @include screen-md {
      order: 3;
      width: 250px;
      padding-bottom: 0; }

    @include screen-sm {
      width: 100%;
      margin-bottom: 24px; } }

  &__image {
    position: absolute;
    top: 48%;
    transform: translateY(-50%);
    right: 0;
    width: 69.48%;
    z-index: 0;

    @include screen-md {
      position: relative;
      top: auto;
      transform: none;
      width: auto;
      order: 1;
      margin-left: -60px;
      margin-right: -60px;
      margin-bottom: 24px; }

    @include screen-sm {
      order: 2;
      margin-bottom: 32px;
      margin-left: -48px;
      margin-right: -48px; }

    img {
      display: block;
      width: 100%;
      height: auto;
      position: relative;
      z-index: 2; } }

  &__circle {
    position: absolute;
    top: 45%;
    right: -34%;
    opacity: 0;
    transform: translate(30%, -50%) scale(0);
    width: 94%;
    border-radius: 50%;
    background: #FFD94D;
    transition: transform 1400ms ease-in-out, opacity 600ms ease;

    &[data-animated] {
      transform: translate(0, -50%) scale(1);
      opacity: 1; }

    @include screen-md {
      top: 54%;
      right: -15%;
      transform: translate(30%, -50%) scale(0);
      width: 85%;

      &[data-animated] {
        transform: translate(0, -50%) scale(1);
        opacity: 1; } }

    @include screen-sm {
      top: 54%;
      right: auto;
      left: 50%;
      transform: translate(-50%, -50%) scale(0);
      width: 80%;
      transition: transform 900ms ease-in-out, opacity 600ms ease;

      &[data-animated] {
        transform: translate(-50%, -50%) scale(1); } }

    &::before {
      content: '';
      display: inline-block;
      padding-top: 100%; } }

  &__slider {

    @include screen-md {
      order: 4;
      position: absolute;
      right: 20px;
      bottom: 0;
      z-index: 3;
      width: 200px; }

    @include screen-sm {
      position: relative;
      right: auto;
      width: 100%; } } }
