.input {
  position: relative;

  &.error {

    .input__field {
      border-color: #DA2041; } }

  &__label {
    font-weight: 400;
    font-size: 20px;
    line-height: 125%;
    letter-spacing: -0.01em;
    color: #595D62;
    position: absolute;
    top: 0;
    left: 0;
    transform: translateY(32px);
    pointer-events: none;
    transition: transform 300ms ease;

    @include screen-xxl {
      font-size: 16px;
      transform: translateY(18px); } }

  &__field {
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    -ms-progress-appearance: none;
    width: 100%;
    display: block;
    height: 97px;
    line-height: 97px;
    border: none;
    border-bottom: 2px solid #D9D9D9;
    background: none;
    outline: none !important;
    box-shadow: nond;
    font-weight: 400;
    font-size: 36px;
    letter-spacing: 0.02em;
    color: #242424;
    border-radius: 0;
    transition: border 300ms ease;

    @include screen-xxl {
      height: 62px;
      line-height: 62px;
      font-size: 24px; } }

  &.focus {

    .input__label {
      transform: translateY(-20px); } } }
