.steps {
  padding: em(120) 0;
  background: #F0FAFF;
  overflow: hidden;

  @include screen-md {
    padding: 120px 0; }

  @include screen-sm {
    padding: 100px 0; }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: em(80);

    @include screen-md {
      padding-bottom: 60px; }

    @include screen-sm {
      text-align: center;
      justify-content: center; } }

  &__controlls {

    @include screen-sm {
      display: none; } } }
