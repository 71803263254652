.services {
  padding-top: em(170);
  padding-bottom: em(120);
  position: relative;
  overflow: hidden;

  @include screen-md {
    padding-top: 71px;
    padding-bottom: 138px; }

  @include screen-sm {
    padding-top: 0; }

  &--simple {
    padding: em(90) 0 0 0;

    @include screen-md {
      padding-top: 30px;
      padding-bottom: 0; }

    @include screen-sm {
      padding-top: 50px;
      padding-bottom: 40px; } }

  &__wrapper {
    position: relative; }

  &__circle {
    content: '';
    position: absolute;
    top: em(-170);
    right: calc(-11% - #{em(120)});
    width: 59.12%;
    background: #F0FAFF;
    z-index: -1;
    border-radius: 50%;

    max-width: 1500px;

    @include screen-md {
      width: 820px;
      right: -390px;
      top: -71px; }

    @include screen-sm {
      width: 735px;
      right: auto;
      left: 50%;
      transform: translateX(-50%);
      top: 50px; }

    &::before {
      content: '';
      display: block;
      padding-top: 100%; } }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: em(80);

    @include screen-md {
      padding-bottom: 60px; }

    @include screen-sm {
      text-align: center;
      justify-content: center; } }

  &__controlls {

    @include screen-sm {
      display: none; } }

  &__slider {
    margin-bottom: em(60);

    @include screen-md {
      margin-bottom: 60px; }

    @include screen-sm {
      margin-bottom: 24px; } }

  &__more-link {

    @include screen-sm {
      display: flex;
      justify-content: center; } } }

