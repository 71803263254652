.services-slider {
  overflow: visible;

  &__item {
    background: #FFFFFF;
    box-shadow: 0px 15px 50px rgba(0, 0, 0, 0.07);
    border-radius: em(40);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: em(40);
    opacity: 0;
    transition: opacity 300ms ease;
    height: auto;
    user-select: none;

    @include screen-md {
      width: 260px !important;
      padding: 24px 28px;
      border-radius: 24px; }

    &.swiper-slide-visible {
      opacity: 1; }

    body:not(.mobile) &:hover {

      .services-slider {

        &__button {
          background: #2D68FF;
          border-color: #2D68FF;
          color: #fff; } } } }

  &__title {
    text-align: center;
    margin-bottom: em(28);

    span {
      font-weight: 600;
      font-size: em(24);
      line-height: 135%;
      color: #242424;

      @include screen-max(1280) {
        font-size: 16px; }

      @include screen-md {
        font-size: 20px; } }

    @include screen-md {
      margin-bottom: 18px; } }

  &__badge {
    background: #F0FAFF;
    border-radius: em(20);
    padding: em(12);
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: em(24);
    right: em(24);

    @include screen-md {
      border-radius: 12px;
      padding: 6px;
      top: 16px;
      right: 16px; } }

  &__badge-icon {
    width: em(28);
    height: em(28);
    margin-bottom: em(2);

    @include screen-md {
      width: 20px;
      height: 20px;
      margin-bottom: 2px; }

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: contain; } }

  &__badge-text {
    font-weight: 500;
    font-size: em(13);
    line-height: 135%;
    letter-spacing: 0.01em;
    color: #2D68FF;
    text-align: center;

    @include screen-max(1477) {
      font-size: 10px; }

    @include screen-md {
      font-size: 12px; } }

  &__img {
    width: 100%;
    max-width: em(129);
    margin-bottom: em(28);

    @include screen-md {
      max-width: 100px;
      margin-bottom: 18px; }

    img {
      display: block;
      width: 100%;
      height: auto; } }

  &__list {
    width: 100%;
    max-width: em(162);
    margin-bottom: em(28);
    display: flex;
    flex-direction: column;

    @include screen-md {
      margin-bottom: 18px; } }

  &__list-item {
    padding-left: em(23);
    position: relative;
    margin-bottom: em(5);

    span {
      font-weight: 500;
      font-size: em(17);
      line-height: 135%;
      color: #4E4E4E;

      @include screen-max(1357) {
        font-size: 12px; }

      @include screen-md {
        font-size: 13px; } }

    @include screen-md {
      margin-bottom: 4px; }

    &:last-child {
      margin-bottom: 0; }

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      width: em(7);
      height: em(7);
      border-radius: 50%;
      transform: translateY(-50%);
      background: #2D68FF; } }

  &__bottom {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 100%;
    margin-top: auto; }

  &__button {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: #2D68FF;
    height: em(56);
    border: 2px solid #C4D4FF;
    border-radius: em(45);
    width: 100%;
    padding: 0 em(16);
    margin-bottom: em(16);
    transition: background 300ms ease, color 300ms ease, border 300ms ease;

    span {
      font-weight: 700;
      font-size: em(15);
      line-height: 160%;

      @include screen-max(1409) {
        font-size: 11px; }

      @include screen-md {
        font-size: 13px; } }

    @include screen-md {
      height: 48px; } }

  &__price {
    text-align: center;
    display: flex;
    align-items: baseline;
    color: #242424;

    &-main {
      font-weight: 600;
      font-size: em(32);
      line-height: 135%;

      @include screen-md {
        font-size: 28px; } }

    &-currency {
      padding-left: em(4);
      font-size: em(24);
      line-height: 135%;

      @include screen-md {
        font-size: 20px; } }

    &--old {
      font-weight: 500;
      font-size: em(17);
      line-height: 135%;
      text-decoration-line: line-through;
      color: #979BBE;

      @include screen-md {
        font-size: 15px; } } }

  .swiper-btn {

    &--prev, &--next {
      top: 50%;
      transform: translateY(-50%); }

    &--prev {
      left: calc(50% - 130px - 34px); }

    &--next {
      right: calc(50% - 130px - 34px); } } }
