.modal {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 10;
  width: 100%;
  overflow-x: hidden;
  overflow-y: overlay;
  opacity: 0;
  visibility: hidden;
  transition: opacity 300ms ease, visibility 300ms ease;
  min-width: 375px;
  background: #fff;

  &::-webkit-scrollbar {
    width: 4px;
    background-color: rgba(0, 0, 0, 0); }

  &::-webkit-scrollbar-track {
    box-shadow: none;
    background-color: rgba(0, 0, 0, 0); }

  &::-webkit-scrollbar-thumb {
    background: rgba(36, 36, 36, 0.5);
    border-radius: 3px; }

  &.success {

    .modal {

      &__form-content {
        display: none; }

      &__form-success {
        display: flex; } } }

  &.active {
    opacity: 1;
    visibility: visible;

    .modal__content {
      transform: translateY(0);
      opacity: 1; } }

  &__wrapper {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100%;
    width: 100%;
    padding: 0;
    z-index: 2;
    overflow: hidden;
    padding: 0 em(40);

    @include screen-md {
      padding: 0 40px; } }

  &__close {
    box-sizing: content-box;
    position: absolute;
    top: em(20);
    right: em(20);
    z-index: 3;
    display: flex;
    align-items: center;

    span {
      font-weight: 400;
      font-size: em(19);
      line-height: 170%;
      color: rgba(#242424, .5);

      @include screen-md {
        font-size: 16px; } }

    @include screen-md {
      top: 24px;
      right: 24px; }

    svg {
      display: block;
      width: em(60);
      height: em(60);
      stroke: #292D32;
      margin-left: em(16);

      @include screen-md {
        width: 40px;
        height: 40px;
        margin-left: 12px; } } }

  &__content {
    width: 100%;
    max-width: em(740);
    position: relative;
    z-index: 2;
    padding: em(80) 0;
    opacity: 0;
    transform: translateY(50px);
    transition: transform 600ms ease-out, opacity 600ms ease-out;

    @include screen-md {
      max-width: 391px;
      padding: 80px 0; } }

  &__circle {
    border-radius: 50%;
    overflow: hidden;
    position: absolute;

    &::before {
      content: '';
      display: inline-block;
      padding-top: 100%; }

    &--yellow {
      width: em(1049);
      background: #FFD94D;
      top: em(-820);
      left: em(-475);

      @include screen-md {
        width: 600px;
        top: -434px;
        left: -267px; }

      @include screen-sm {
        top: -470px;
        left: -343px; } }

    &--blue {
      width: em(1135);
      background: #F0FAFF;
      bottom: em(-842);
      right: em(-608);

      @include screen-md {
        width: 667px;
        bottom: -488px;
        right: -359px; }

      @include screen-sm {
        bottom: -537px;
        right: -429px; } } }

  &__title {
    color: #242424;
    width: 100%;
    max-width: em(552);
    margin-bottom: em(50);

    span {
      font-weight: 500;
      font-size: em(56);
      line-height: 125%;
      letter-spacing: -0.01em;

      @include screen-md {
        font-size: 32px; } }

    @include screen-md {
      max-width: none;
      margin-bottom: 50px; }

    &--sm {
      max-width: none !important;
      margin-bottom: em(24);

      @include screen-md {
        margin-bottom: 12px; } } }

  &__form {}

  &__form-field {
    padding-bottom: em(75);

    @include screen-md {
      padding-bottom: em(50); } }

  &__submit {
    width: em(300);

    @include screen-md {
      width: 100%;
      display: flex;
      justify-content: center; } }

  &__text {
    color: #595D62;
    padding-bottom: em(60);

    span {
      font-weight: 400;
      font-size: em(22);
      line-height: 170%;

      @include screen-md {
        font-size: 14px; } }

    @include screen-md {
      padding-bottom: 40px; } }

  &__backlink {
    width: em(300);

    @include screen-md {
      width: 218px; } }

  &__icon {
    width: em(152);
    height: em(152);
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #F0FAFF;
    margin-bottom: em(64);

    @include screen-md {
      width: 88px;
      height: 88px;
      margin-bottom: 24px; }

    img {
      display: block;
      width: em(72);
      height: em(72);
      object-fit: contain;

      @include screen-md {
        width: 48px;
        height: 48px; } } }

  &__form-content {
    display: block; }

  &__form-success {
    display: none;
    flex-direction: column;
    align-items: center;
    text-align: center; } }
