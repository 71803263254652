.custom-scroll::-webkit-scrollbar {
  width: 4px;
  background-color: rgba(#E9EFF7, .7); }

.custom-scroll::-webkit-scrollbar-track {
  box-shadow: none; }

.custom-scroll::-webkit-scrollbar-thumb {
  background: #647A9C;
  border-radius: 3px; }
