.swiper-btn {
  position: absolute;
  display: flex;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.6);
  box-shadow: 0px 6px 30px rgba(23, 28, 69, 0.1);
  backdrop-filter: blur(7.5px);
  transition: opacity 300ms ease;
  display: none;
  z-index: 100;

  @include screen-sm {
    display: flex; }

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 24px;
    height: 24px; }

  &.swiper-button-disabled {
    opacity: 0; }

  &--prev {

    &::before {
      background: url('../img/swiper-btn/prev.svg') 50% 50% no-repeat;
      background-size: contain; } }

  &--next {

    &::before {
      background: url('../img/swiper-btn/next.svg') 50% 50% no-repeat;
      background-size: contain; } } }
