// @font-face {
//   font-family: 'Neue Alte Grotesk';
//   src: url('../fonts/NeueAlteGrotesk-Regular.eot?#iefix') format('embedded-opentype'),
//        url('../fonts/NeueAlteGrotesk-Regular.woff2') format('woff2'),
//        url('../fonts/NeueAlteGrotesk-Regular.woff') format('woff'),
//        url('../fonts/NeueAlteGrotesk-Regular.ttf') format('truetype');
//   font-weight: normal;
//   font-style: normal;
// }

@font-face {
  font-family: 'Euclid Circular A';
  src: url('../fonts/euclid-circular/Euclid-Circular-A-Light.woff2') format('woff2'),
       url('../fonts/euclid-circular/Euclid-Circular-A-Light.woff') format('woff'),
       url('../fonts/euclid-circular/Euclid-Circular-A-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Euclid Circular A';
  src: url('../fonts/euclid-circular/Euclid-Circular-A-Regular.woff2') format('woff2'),
       url('../fonts/euclid-circular/Euclid-Circular-A-Regular.woff') format('woff'),
       url('../fonts/euclid-circular/Euclid-Circular-A-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Euclid Circular A';
  src: url('../fonts/euclid-circular/Euclid-Circular-A-Medium.woff2') format('woff2'),
       url('../fonts/euclid-circular/Euclid-Circular-A-Medium.woff') format('woff'),
       url('../fonts/euclid-circular/Euclid-Circular-A-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Euclid Circular A';
  src: url('../fonts/euclid-circular/Euclid-Circular-A-SemiBold.woff2') format('woff2'),
       url('../fonts/euclid-circular/Euclid-Circular-A-SemiBold.woff') format('woff'),
       url('../fonts/euclid-circular/Euclid-Circular-A-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Euclid Circular A';
  src: url('../fonts/euclid-circular/Euclid-Circular-A-Bold.woff2') format('woff2'),
       url('../fonts/euclid-circular/Euclid-Circular-A-Bold.woff') format('woff'),
       url('../fonts/euclid-circular/Euclid-Circular-A-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}
