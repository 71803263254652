
.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  background: transparent;
  transition: background 300ms ease, box-shadow 150ms ease;
  min-width: 375px;

  &.active {
    background: #fff; }

  .container {
    max-width: em(1586);

    @include screen-xxxl {
      max-width: 100%; }

    @include screen-md {
      padding: 0 24px; }

    @include screen-sm {
      padding: 0 16px; } }

  &.collapsed {
    background: #fff;
    box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.06);

    .header {

      &__wrapper {
        padding: em(18) 0; }

      &__menu {

        @include screen-lg {
          top: em(68);
          height: calc(100% - #{em(68)}); }

        @include screen-md {
          top: 68px;
          height: calc(100% - 68px); } } } }

  &__wrapper {
    display: flex;
    align-items: center;
    padding: em(33) 0;
    transition: padding 150ms ease;
    position: relative;

    @include screen-lg {
      flex-direction: row-reverse;
      justify-content: space-between; }

    @include screen-md {
      padding: 32px 0; }

    @include screen-sm {
      padding: 24px 0; } }

  &__logo {
    width: em(179);
    height: em(44);
    display: block;
    margin-right: em(60);
    position: relative;
    top: em(-6);

    @include screen-xl {
      margin-right: 32px; }

    @include screen-lg {
      width: em(157);
      height: em(38);
      position: absolute;
      top: calc(50% - #{em(4)});
      left: 50%;
      transform: translate(-50%, -50%);
      margin-right: 0; }

    @include screen-md {
      width: 157px;
      height: 38px;
      top: calc(50% - 4px); }

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: contain; } }

  &__lang-switcher {
    display: flex;
    align-items: center;

    @include screen-lg {
      display: none;
      position: absolute;
      bottom: em(48);
      left: 50%;
      transform: translateX(-50%); }

    @include screen-md {
      bottom: 48px; }

    &--sm {
      display: none;

      @include screen-lg {
        display: flex; } } }

  &__lang {
    min-width: em(50);
    height: em(37);
    background: #fff;
    border-radius: em(40);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 em(14);
    color: rgba(#332D2D, .5);

    margin-right: em(6);
    transition: color 300ms ease, background 300ms ease;

    span {
      font-weight: 500;
      font-size: em(14);
      line-height: 1;
      letter-spacing: 0.01em; }

    @include screen-md {
      margin-right: 4px;
      height: 44px;
      padding: 0 20px;
      border-radius: 40px;
      min-width: auto; }

    &:last-child {
      margin-right: 0; }

    &.active {
      background: #F2F6FC;
      color: #2D68FF; }

    body:not(.mobile) &:hover:not(.active) {
      background: #F2F6FC;
      color: #2D68FF; } }

  &__menu {
    position: absolute;
    left: 50%;
    top: 50%;
    min-width: 375px;

    @media only screen and ( min-width: 1280px ) {
      transform: translate(-50%, -50%) !important;
      opacity: 1 !important;
      display: block !important; }

    @include screen-lg {
      position: fixed;
      top: em(96);
      left: 0;
      width: 100%;
      height: calc(100% - #{em(96)});
      transform: translate(0, 0);
      background: #fff;
      overflow-y: auto;
      display: none; }

    @include screen-md {
      top: 96px;
      height: calc(100% - 96px); } }

  &__menu-wrapper {

    @include screen-lg {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      min-height: 100%;
      padding: em(60) em(40) em(170) em(40);
      overflow: hidden; }

    @include screen-md {
      padding: 60px 40px 170px 40px; }

    @include screen-sm {
      padding: 40px 32px 130px 32px;

      &::before {
        width: 481px;
        height: 481px;
        left: -209px;
        bottom: -218px; } } }

  &__list {
    display: flex;
    align-items: center;
    position: relative;

    @include screen-lg {
      flex-direction: column; } }

  &__item {
    display: block; }

  &__link {
    display: block;
    color: #332D2D;
    padding: 16px;
    transition: color 300ms ease;

    span {
      font-weight: 500;
      font-size: em(16);
      line-height: 1;

      @include screen-lg {
        font-size: em(28);
        line-height: 114%; }

      @include screen-md {
        font-size: 28px;
        line-height: 32px; } }

    @include screen-lg {
      padding: em(28); }

    @include screen-md {
      padding: 28px; }

    @include screen-sm {
      font-size: 26px;
      line-height: 20px; }

    body:not(.mobile) &:hover {
      color: #2D68FF; } }

  &__phone {
    margin-left: auto;
    height: em(44);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 em(28);

    text-transform: uppercase;
    color: #000000;
    white-space: nowrap;

    border: 2px solid #000000;
    border-radius: em(45);
    transition: color 300ms ease, background 300ms ease, border 300ms ease, box-shadow 300ms ease;

    span {
      font-weight: 500;
      font-size: em(16);
      line-height: em(24);
      letter-spacing: 0.02em;

      @include screen-md {
        font-size: 14px;
        display: none; } }

    @include screen-md {
      padding: 0;
      width: 44px;
      height: 44px;
      border-radius: 50%;
      overflow: hidden;
      border: none;
      background: #F2F6FC;
      position: relative;

      &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 28px;
        height: 28px;
        background: url('../img/header/phone.svg') 50% 50% no-repeat;
        background-size: contain;
        opacity: 1;
        transition: opacity 300ms ease; }

      &::after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 28px;
        height: 28px;
        background: url('../img/header/phone-light.svg') 50% 50% no-repeat;
        background-size: contain;
        opacity: 0;
        transition: opacity 300ms ease; } }

    body:not(.mobile) &:hover {
      color: #fff;
      background: #2D68FF;
      border-color: #2D68FF;
      box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.11);

      @include screen-md {

        &::before {
          opacity: 0; }

        &::after {
            opacity: 1; } } }

    &:active {
      background: #0D48E0;
      border-color: #0D48E0;
      box-shadow: none; } }

  &__burger {
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: em(28);
    height: em(28);
    padding: em(2);
    z-index: 999999999;
    transition: opacity 600ms ease, transform 600ms ease;

    @include screen-lg {
      display: flex; }

    @include screen-md {
      width: 28px;
      height: 28px;
      padding: 2px; }

    span {
      width: 100%;
      height: em(2);
      background: #000;
      margin-bottom: em(6);
      transition: all 300ms ease;

      @include screen-md {
        height: 2px;
        margin-bottom: 6px; }

      &:last-child {
        margin-bottom: 0; } }

    &.active {

      span {

        &:first-child {
          transform: rotate(45deg) translate(em(3), em(3));

          @include screen-md {
            transform: rotate(45deg) translate(3px, 3px); } }

        &:last-child {
          transform: rotate(-45deg) translate(em(3), em(-3));

          @include screen-md {
            transform: rotate(-45deg) translate(3px, -3px); } } } } } }

