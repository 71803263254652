.form {

  &__field {
    padding-bottom: 32px; }

  &__checkbox {
    padding-bottom: 40px; }

  &__submit {
    display: flex;
    justify-content: center;
    padding-top: 8px; } }

.error-msg {
  padding-top: 12px;
  font-weight: 400;
  font-size: 20px;
  line-height: 125%;
  letter-spacing: -0.01em;
  color: #DA2041;
  display: none;

  @include screen-xxl {
    padding-top: 8px;
    font-size: 14px; } }

.error {

  .error-msg {
    display: block; } }

.label {
  font-weight: 400;
  font-size: 20px;
  line-height: 130%;
  letter-spacing: -0.02em;
  color: #FFFFFF; }
