.hide-md {
  display: block !important;

  @include screen-md {
    display: none !important; } }

.show-md {
  display: none !important;

  @include screen-md {
    display: block !important; } }

.hide-only-md {
  display: block;

  @include screen-md {
    display: none; }

  @include screen-sm {
    display: block; } }

.show-sm {
  display: none;

  @include screen-sm {
    display: block; } }

.hide-sm {
  display: block !important;

  @include screen-sm {
    display: none !important; } }

.hide-fl-sm {
  display: flex !important;

  @include screen-sm {
    display: none !important; } }
