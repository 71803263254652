.swiper-navigation {
  position: relative;
  display: flex;
  align-items: center;
  user-select: none;

  &__btn {
    width: em(64);
    height: em(64);
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    transition: background 300ms ease, opacity 300ms ease;
    border-radius: 50%;
    overflow: hidden;
    user-select: none;

    @include screen-md {
      width: 48px;
      height: 48px; }

    body:not(.mobile) &:hover:not(.swiper-button-disabled) {
      background: #2D68FF;

      svg {
        stroke: #fff; } }

    &--prev {
      margin-right: em(26);

      @include screen-md {
        margin-right: 16px; } }

    &--next {}

    &.swiper-button-disabled {
      opacity: 0.2;
      pointer-events: none;
      background: none; } }

  svg {
    display: block;
    width: em(32);
    height: em(32);
    stroke: #242424;
    transition: stroke 300ms ease;

    @include screen-md {
      width: 24px;
      height: 24px; } } }
