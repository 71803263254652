.f-plate {
  position: fixed;
  left: 50%;
  bottom: em(20);
  z-index: 9;
  width: em(730);
  transform: translateX(-50%);

  background: #FFFFFF;
  box-shadow: 0px 10px 50px rgba(12, 26, 99, 0.07);
  border-radius: em(70);

  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: em(22) em(32) em(22) em(130);
  transition: opacity 300ms ease, visibility 300ms ease;

  opacity: 0;
  visibility: hidden;

  @include screen-max(1366) {
    padding: 16px 22px 16px 92px;
    width: 519px; }

  @include screen-md {
    padding: 10px 12px 10px 84px;
    width: 414px;
    bottom: 16px;
    border-radius: 70px; }

  @include screen-sm {
    width: auto; }

  &.visible {
    opacity: 1;
    visibility: visible; }

  &__image {
    position: absolute;
    left: 0;
    top: 0;
    width: em(100);
    height: em(100);
    border-radius: 50%;
    overflow: hidden;
    border: 4px solid #FFD84C;

    @include screen-max(1366) {
      width: 71px;
      height: 71px; }

    @include screen-md {
      width: 64px;
      height: 64px; }

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover; } }

  &__text {
    padding-right: em(24);
    color: #242424;

    span:not(.blue) {
      font-weight: 500;
      font-size: em(19);
      line-height: 170%;

      @include screen-max(1366) {
        font-size: 13px; }

      @include screen-md {
        font-size: 14px;
        line-height: 130%; } }

    @include screen-max(1366) {
      padding-right: 16px; }

    @include screen-md {
      padding-right: 16px; }

    @include screen-sm {
      display: none; } }

  &__btn {
    width: em(200);
    flex-shrink: 0;

    @include screen-max(1366) {
      width: 142px;
      height: 40px; }

    @include screen-md {
      width: 160px; }

    @include screen-sm {
      width: auto; }

    .btn--md {

      @include screen-md {
        height: 44px;

        span {
          font-size: 12px;
          white-space: nowrap; } } } } }

