.f-slider {
  position: relative;

  @include screen-sm {
    padding: 0 16px; }

  &__container {
    overflow: visible; }

  &__item {
    height: auto;
    width: em(526) !important;
    background: #FFFFFF;
    box-shadow: 0px 15px 50px rgba(0, 0, 0, 0.07);
    border-radius: em(16);
    padding: em(80) em(70);

    @include screen-md {
      width: 311px !important;
      padding: 32px; }

    @include screen-sm {
      width: 100% !important; } }

  &__header {
    padding-bottom: em(28);
    display: flex;
    align-items: center;
    position: relative;

    @include screen-md {
      flex-direction: column;
      padding-bottom: 8px; } }

  &__img {
    flex-shrink: 0;
    width: em(80);
    height: em(80);
    margin-right: em(24);
    border-radius: 50%;
    overflow: hidden;

    @include screen-md {
      width: 70px;
      height: 70px;
      margin-right: 0;
      margin-bottom: 16px; }

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover; } }

  &__name {
    font-weight: 500;
    font-size: em(24);
    line-height: 150%;
    color: #242424;

    @include screen-md {
      font-size: 20px; } }

  &__icon {
    width: em(30);
    height: em(30);
    margin-top: em(10);

    @include screen-md {
      width: 24px;
      height: 24px;
      position: absolute;
      top: -8px;
      right: -8px;
      margin-top: 0; }

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: contain; } }

  &__description {
    font-weight: 400;
    font-size: em(17);
    line-height: 160%;
    color: #595D62;

    @include screen-max(1582) {
      font-size: 14px; }

    @include screen-md {
      font-size: 14px;
      text-align: center; } }

  .swiper-btn {

    &--prev, &--next {
      top: 109px; }

    &--prev {
      left: -12px; }

    &--next {
      right: -12px; } } }
